import React, { useState, useEffect } from "react";
import "./index.css";
import { Result, Select, Tabs } from "antd";
import { Headphone } from "../../components/svgJS/Headphone";
import { Lifesaver } from "../../components/svgJS/Lifesaver";
import { VideoBlack } from "../../components/svgJS/VideoBlack";
import EventAndTechnicalSupport from "./EventAndTechnicalSupport";
import {
  generateHelmet,
  isAdminCustomer,
  isImage,
  isNonAdminLevelUser,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player/lazy";
import { HeartFilled, HeartOutlined, SmileOutlined } from "@ant-design/icons";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getEvents } from "../../utils/commonApis";

const HelpAndSupport = (props) => {
  const { TabPane } = Tabs;
  // let { socket } = props;

  var CryptoJS = require("crypto-js");
  const [userType, setUserType] = useState("");
  const [tutorialKey, setTutorialKey] = useState(null);
  const [eveSupportKey, setEveSupportKey] = useState(null);

  const [techEveSupportKey, setTechEveSupportKey] = useState(null);

  const [loader, setLoader] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [mediaReactionDetails, setMediaReactionDetails] = useState(null);
  const [activeKeyData, setActiveKeyData] = useState("0");

  const [event_support_flags, set_event_support_flags] = useState({
    is_chat_active: true,
    is_video_call_active: true,
  });
  const [tech_support_flags, set_tech_support_flags] = useState({
    is_chat_active: true,
    is_video_call_active: true,
  });

  const [eventList, setEventList] = useState([]);
  const [currentEventID, setCurrentEventID] = useState(null);

  const helpAndSupportNotificationData = useSelector(
    (state) => state?.header?.helpAndSupportData
  );

  const { Option } = Select;

  useEffect(async () => {
    if (localStorage.getItem("mediaReactionDetails")) {
      let mediaReactionDetails = JSON.parse(
        localStorage.getItem("mediaReactionDetails")
      );
      setMediaReactionDetails(mediaReactionDetails);
      setIsLiked(mediaReactionDetails?.is_help_support_liked);
      setLikeCount(mediaReactionDetails?.help_support_like_count);
    }

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData === "SUPER ADMIN" || decryptedData === "CUSTOMER") {
        localStorage.setItem("event_name", "");
      }
      setUserType(decryptedData);

      let editor_details;
      let new_event_flags, new_tech_flags;
      if (!isAdminCustomer(decryptedData)) {
        const responseEventForSupport = await ApiCall(
          "GET",
          "",
          `/admin/events/eventsupport/settings/get`,
          ""
        );
        console.log("responseEventForSupport = ", responseEventForSupport);
        if (responseEventForSupport?.data?.data?.editor_details) {
          editor_details = responseEventForSupport?.data?.data?.editor_details;
          new_event_flags = {
            is_chat_active: editor_details?.event_support?.is_chat_active,
            is_video_call_active:
              editor_details?.event_support?.is_video_call_active,
          };
          set_event_support_flags(new_event_flags);
          new_tech_flags = {
            is_chat_active:
              editor_details?.event_technical_support?.is_chat_active,
            is_video_call_active:
              editor_details?.event_technical_support?.is_video_call_active,
          };
          set_tech_support_flags(new_tech_flags);
        }
      }

      if (
        (isNonAdminLevelUser(decryptedData) ||
          decryptedData === "BRAND ADMIN") &&
        localStorage.getItem("control_media_url")
      ) {
        setTutorialKey("0");
        if (editor_details) {
          console.log("set 111");
          if (
            new_event_flags.is_chat_active ||
            new_event_flags.is_video_call_active
          )
            setEveSupportKey("1");
          if (
            new_tech_flags.is_chat_active ||
            new_tech_flags.is_video_call_active
          )
            setTechEveSupportKey("2");
        }
      } else {
        console.log("set 222");
        setEveSupportKey("0");
        setTechEveSupportKey("1");
        setPlaying(false);
      }
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }

    const eventsData = await getEvents();
    // let id = eventsData[0].event_id;
    // if (eventsData.length === 1) {
    //   setCurrentEventID(id);
    // }
    // const localData_event_id = parseInt(localStorage.getItem("event_id"));

    // const events = eventsData.filter((item) => {
    //   return item.event_id === localData_event_id;
    // });

    console.log("events", eventsData);

    setEventList(eventsData);
  }, []);

  useEffect(() => {
    if (
      helpAndSupportNotificationData?.userId &&
      eveSupportKey &&
      techEveSupportKey
    ) {
      if (
        helpAndSupportNotificationData?.supportType ===
        "EVENT_HELP_SUPPORT_AGENT"
      ) {
        setLoader(true);
        setActiveKeyData("");
        setTimeout(() => {
          setActiveKeyData(eveSupportKey);
          setLoader(false);
        }, 500);
      } else {
        setLoader(true);
        setActiveKeyData("");

        setTimeout(() => {
          setActiveKeyData(techEveSupportKey);
          setLoader(false);
        }, 500);
      }
    }
  }, [helpAndSupportNotificationData, eveSupportKey, techEveSupportKey]);

  const updateLikeCount = async () => {
    let body = {
      is_liked: !isLiked,
      media_type: "HELP_SUPPORT",
    };
    setIsLiked(!isLiked);
    const response = await ApiCall(
      "POST",
      body,
      "/admin/events/media/reaction"
    );

    if (response.data.code === 200) {
      setLikeCount(response?.data?.data?.total_like_count);
    } else toast.error(response.data.message);
  };

  const eventName = localStorage?.getItem("event_name");

  if (loader) return <CommonLoader />;
  else
    return (
      <>
        <div>
          <div className="d-flex justify-content-between">
            {generateHelmet(
              eventName?.length > 0 ? eventName : "Gravit8",
              "Help And Support"
            )}
            <h4 className="fs-24 my-2">Help & Support</h4>
            {userType === "CUSTOMER" ? (
              <Select
                showSearch
                placeholder="Select Event"
                className="fs-bold-14 help_suppport_event_ddl"
                // value={currentEventID}
                value={
                  currentEventID
                    ? currentEventID !== null
                      ? currentEventID
                      : null
                    : null
                }
                // disabled={disableEvent}
                onChange={(val) => setCurrentEventID(val)}
                optionFilterProp="children"
                listHeight={120}
                listItemHeight={4}
              >
                {!isEmpty(eventList) &&
                  eventList.map((data, index) => {
                    return (
                      <Option key={"event_" + index} value={data.event_id}>
                        {data.event_name}
                      </Option>
                    );
                  })}
              </Select>
            ) : null}
          </div>

          {currentEventID || userType !== "CUSTOMER" ? (
            <div className="card-container mt-1">
              <Tabs
                type="card"
                activeKey={activeKeyData}
                onChange={(activeKey) => {
                  setLoader(true);
                  if (isNonAdminLevelUser(userType)) {
                    if (activeKey === "1" || activeKey === "2")
                      setPlaying(false);
                    else setPlaying(true);
                  }
                  setTimeout(() => {
                    setActiveKeyData(activeKey);
                    setLoader(false);
                  }, 500);
                }}
              >
                {isNonAdminLevelUser(userType) &&
                localStorage.getItem("control_media_url") &&
                !isEmpty(localStorage.getItem("control_media_url")) ? (
                  <TabPane
                    tab={
                      <>
                        <span className="mx-2">Tutorial Image/Video</span>
                        <VideoBlack />
                      </>
                    }
                    key={tutorialKey}
                  >
                    <div style={{ height: "70vh" }} className="bg-light">
                      {mediaReactionDetails ? (
                        <div className="video_tutorial_like">
                          <span>{likeCount}</span>
                          {isLiked ? (
                            <HeartFilled
                              onClick={() => updateLikeCount()}
                              className="cursor-pointer pdf_brand_zone_like_red"
                              style={{
                                fontSize: "18px",
                                marginRight: "40px",
                              }}
                            />
                          ) : (
                            <HeartOutlined
                              onClick={() => {
                                updateLikeCount();
                              }}
                              className="cursor-pointer"
                              style={{
                                fontSize: "18px",
                                marginRight: "40px",
                              }}
                            />
                          )}
                        </div>
                      ) : null}
                      {isImage(localStorage.getItem("control_media_url")) ? (
                        <>
                          <div className="d-flex justify-content-center h-100">
                            <img
                              className="control-media-img-tutorial-video"
                              src={localStorage.getItem("control_media_url")}
                            />
                          </div>
                        </>
                      ) : (
                        <ReactPlayer
                          playing={playing}
                          controls={true}
                          url={localStorage.getItem("control_media_url")}
                          className="react-player-class"
                          width="100%"
                          height="500px"
                        />
                      )}
                    </div>
                  </TabPane>
                ) : null}
                {eveSupportKey ? (
                  <TabPane
                    tab={
                      <>
                        <span className="mx-2">Event Support</span>
                        <Headphone />
                      </>
                    }
                    key={eveSupportKey}
                  >
                    <EventAndTechnicalSupport
                      currentEventID={currentEventID}
                      isEventSupport={true}
                      {...props}
                      event_support_flags={event_support_flags}
                      tech_support_flags={tech_support_flags}
                    />
                  </TabPane>
                ) : null}

                {techEveSupportKey ? (
                  <TabPane
                    tab={
                      <>
                        <span className="mx-2">Technical Support</span>
                        <Lifesaver />
                      </>
                    }
                    key={techEveSupportKey}
                  >
                    <EventAndTechnicalSupport
                      currentEventID={currentEventID}
                      isEventSupport={false}
                      {...props}
                      event_support_flags={event_support_flags}
                      tech_support_flags={tech_support_flags}
                    />
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
          ) : (
            <div className="empty_event">
              <Result
                icon={<SmileOutlined />}
                title={
                  <h4 className="fs-pink">
                    Please choose your event in the top right corner
                  </h4>
                }
              />
            </div>
          )}
        </div>
      </>
    );
};

export default HelpAndSupport;
