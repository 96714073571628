/**
 * @name VistaContext
 */

import React, {
	createContext,
	useReducer,
	useContext,
	useEffect,
	useRef,
	useCallback,
	useState,
} from "react";
import logger from "use-reducer-logger";
import { IFrameManager } from "@dbpx/dbutils";
import {
	standsData,
	hotSpotData,
	HOTSPOT_TYPES,
	locationData,
	hallData,
	exteriorPublished,
	concoursePublished,
	auditoriumPublished,
	networkingPublished,
	satellitePublished,
} from "../apiData";
import AppContext, { ACTIONS as AppActions } from "./AppContext";
import moment from "moment";
import { getConcourseDetails } from "../components/popup/Api/api";
import { useDispatch, useSelector } from "react-redux";
import {
	setBoothLocationNumber,
	setConcourseData,
	setExpoMapRedirectLinkName,
	setVisitBoothData,
	setVistaBrandData,
	setVistaFiles,
} from "../../../../redux/slice/vista";
import { toast } from "react-toastify";
import { getCookie } from "../../../../utils/commonFunctions";
import io from "socket.io-client";
// import { assertOptionalCallExpression } from "../../../../../../../Library/Caches/typescript/4.6/node_modules/@babel/types/lib/index";

const VistaContext = createContext();

// Context Actions
export const ACTIONS = Object.freeze({
	SET_VIEWER: "SET_VIEWER",
	SET_SRC: "SET_SRC",
	SET_LOADED: "SET_LOADED",
});

// Context Initial State
const initialState = {
	viewer: null,
	loaded: false,
};

// Context Reducer
const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_VIEWER:
			return { ...state };
		case ACTIONS.SET_LOADED:
			return { ...state, loaded: true };
		default:
			return { ...state };
	}
};

VistaContext.Provider = ((Provider) => (props) => {
	const { children } = props || {};
	const {
		dispatch: AppDispatch,
		location: vistaLocation,
		stand,
		hall,
		currentPosition,
	} = useContext(AppContext);

	// state
	const [manager, setManager] = useState();

	// ref
	const frame = useRef();
	const frameCache = useRef();
	const newSocket = io(process.env.REACT_APP_SOCKET_URL);

	const [state, dispatch] = useReducer(reducer, initialState);
	const [socket] = useState(newSocket);

	const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
	const concourseData = useSelector(
		(state) => state?.vistaBrand?.concourseData,
	);

	const expoHallUrl = useSelector((state) => state?.vistaBrand?.expoHallUrl);
	const expoHallSequence = useSelector(
		(state) => state?.vistaBrand?.expo_hall_sequence,
	);

	const brandObj = useSelector((state) => state?.vistaBrand?.brandObj);

	let CryptoJS = require("crypto-js");

	let event_id = localStorage.getItem("event_id");
	let user_type = "";
	let user_id;
	const dispatchRedux = useDispatch();

	const [brandID, setBrandID] = useState(null);

	if (localStorage.getItem("user_type")) {
		const ciphertext = localStorage.getItem("user_type");
		let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
		user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}

	if (getCookie("user_id")) {
		const ciphertext = getCookie("user_id");
		let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
		user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}

	useEffect(() => {
		if (!frame || frame !== frameCache.current) {
			setManager(new IFrameManager(frame));
			frameCache.current = frame;
		}
	}, [frame]);

	// #region Vista event handles
	// Vista is ready for events

	const ready = useCallback(async () => {
		// Dummy Code
		AppDispatch({
			type: AppActions.SET_VISTA_LOADED,
			value: true,
		});

		if (frame && currentPosition === "hallPublished") {
			const targets = hall.stands || [];

			targets.forEach((value) => {
				value.status &&
					frame?.current?.contentWindow?.blazeIT?.showComponent(
						`${value.index}On`,
					);
			});
		} else if (frame && currentPosition === "standPublished") {
			console.log(stand);
			frame?.current?.contentWindow?.blazeIT?.showComponent(`${stand.navKey}`);
			const hotspots = (stand && stand.hotspots) || [];

			hotspots.forEach((value) => {
				value.status &&
					frame?.current?.contentWindow?.blazeIT?.showComponent(
						`${value.index}On`,
					);
			});
		} else if (frame && currentPosition === "concoursePublished") {
			if (user_type === "SUPER ADMIN") {
				if (vistaEventId) {
					event_id = vistaEventId;
					localStorage.setItem("event_id", event_id);
				}
			}

			let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

			let response = await getConcourseDetails({
				event_id: event_id,
				current_timestamp: timeStamp,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			});

			if (response && response?.data?.code === 200) {
				if (response?.data?.data) {
					let { event_settings } = response?.data?.data;

					frame?.current?.contentWindow?.blazeIT?.showComponent("13On"); //eventCalendar
					frame?.current?.contentWindow?.blazeIT?.showComponent("12On"); //eventMap
					event_settings?.is_reception_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("1On");
					event_settings?.is_networking_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("4On");
					event_settings?.is_networking_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("5On");
					event_settings?.is_social_wall_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("8On");
					event_settings?.is_main_hall_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("2On");
					event_settings?.is_main_hall_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("3On");
					event_settings?.is_expo_hall_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("9On");
					event_settings?.is_expo_hall_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("10On");

					event_settings?.is_sponsor_booth_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("6On"); //sponsorBooth

					event_settings?.is_sponsor_banner_active &&
						frame?.current?.contentWindow?.blazeIT?.showComponent("15On");
				}
			}

			let origin = window.location.origin;
			for (let i = 1; i < 8; i++) {
				frame?.current?.contentWindow?.blazeIT
					?.getHotspot(`sponsorvideobanner${i}`)
					?.set("url", `${origin}/videoplayer`);

				frame?.current?.contentWindow?.blazeIT
					?.getHotspot(`sponsorLeft${i}`)
					?.set("url", `${origin}/videoplayer?source=sponsorLeft`);

				frame?.current?.contentWindow?.blazeIT
					?.getHotspot(`sponsorMain${i}`)
					?.set("url", `${origin}/videoplayer?source=sponsorCenter`);

				frame?.current?.contentWindow?.blazeIT
					?.getHotspot(`sponsorRight${i}`)
					?.set("url", `${origin}/videoplayer?source=sponsorRight`);
			}
		}
	});

	const hotspot = useCallback((payload) => {
		console.log(currentPosition, "-------default position-------");
		console.log(payload, "-------load---------");
		let activeHotSpot = [];
		let activeVista = "";

		socket.emit(
			"updateLocation",
			JSON.stringify({
				location: currentPosition,
				event_id: event_id,
				user_id: user_id,
				user_type: user_type,
			}),
		);

		switch (currentPosition) {
			case "exteriorPublished":
				activeHotSpot = exteriorPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				if (payload.data === 1) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `login`,
					});
				}
				break;
			case "concoursePublished":
				console.log(payload, "concourse payload");
				activeHotSpot = concoursePublished.filter(
					(publishData) => publishData.id === payload.data,
				);

				switch (payload.data) {
					case 1:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `reception`,
						});
						break;
					case 2:
						activeVista = "auditoriumPublished";
						break;
					case 3:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `auditorium`,
						});
						break;
					case 4:
						// dispatch({
						//   type: ACTIONS.SET_VISTA_SRC,
						//   value: {
						//     src: `${concourseData?.event_settings?.concourse_url}?media-name=6`,
						//     currentPosition: "concoursePublished",
						//   },
						// });
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `Entry`,
						});
						activeVista = "networkingPublished";
						break;
					case 5:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `networking`,
						});
						break;
					case 6:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `sponsorbooth`,
						});
						break;
					case 8:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `socialwall`,
						});
						break;
					case 9:
					case 10:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `expomap`,
						});
						break;
					case 11:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `eventcalendar`,
						});
						break;
					case 12:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `eventmap`,
						});
						break;
					case 13:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `eventcalendar`,
						});
						break;
					case 15:
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							value: `banner`,
						});
						break;
					default:
						console.log("No relevant hotspot found!");
						activeVista = "concoursePublished";
						break;
				}
				break;
			case "auditoriumPublished":
				activeHotSpot = auditoriumPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "auditoriumPublished";

				if (payload.data === 2) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `audimeet`,
					});
				} else if (payload.data === 3 || payload.data === 4) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `auditorium`,
					});
				}
				break;
			case "networkingPublished":
				activeHotSpot = networkingPublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "networkingPublished";
				if (payload.data === 2 || payload.data === 3) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `networking`,
					});
				}
				break;
			case "hallPublished":
				activeHotSpot = hallData[0].hotspots.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "hallPublished";
				if (
					payload.data === 3 ||
					payload.data === 4 ||
					payload.data === 6 ||
					payload.data === 2 ||
					payload.data === 5
				) {
					AppDispatch({
						type: AppActions.SET_VISTA_SRC,
						value: {
							src: concourseData?.event_settings?.concourse_url,
							currentPosition: "concoursePublished",
						},
					});
				}

				break;
			case "satellitePublished":
				activeHotSpot = satellitePublished.filter(
					(publishData) => publishData.id === payload.data,
				);
				activeVista = "satellitePublished";
				if (payload.data === 2) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `satmeet`,
					});
				} else if (payload.data === 3) {
					AppDispatch({
						type: AppActions.SET_ACTIVE_WINDOW,
						value: `satellite`,
					});
				}
				break;
			case "standPublished":
				const stand = standsData.filter(
					(stand) => stand.brand_id === brandID || brandObj?.brand_id, //brandID -> set when user click on booth building and brandObj is coming from visit booth
				)[0];

				// activeHotSpot = standsData[0].hotspots.filter(
				//   (publishData) => publishData.id === payload.data
				// );

				activeHotSpot = stand?.hotspots?.filter(
					(publishData) => publishData.id === payload.data,
				);

				// AppDispatch({
				//   type: AppActions.SET_ACTIVE_WINDOW,
				//   value: `hallbooth-${payload.data}-${standsData[0].brand_id}`,
				// });

				AppDispatch({
					type: AppActions.SET_ACTIVE_WINDOW,
					value: `hallbooth-${payload.data}-${stand?.brand_id}`,
				});

				activeVista = "standPublished";
				break;
			default:
				console.log("No data for hotspot");
		}

		if (activeHotSpot.length > 0) {
			const data = activeHotSpot[0];

			switch (data.hotSpotType) {
				case HOTSPOT_TYPES.ASSET:
					if (
						currentPosition !== "concoursePublished" &&
						currentPosition !== "auditoriumPublished" &&
						currentPosition !== "satellitePublished" &&
						currentPosition !== "networkingPublished"
					) {
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							// value: { value: data.type, assetUrl: data.url }
							value: `${data.type}`,
						});
					}
					break;
				case HOTSPOT_TYPES.BRAND_ZONE:
					break;
				case HOTSPOT_TYPES.VIEW:
					// AppDispatch({
					//     type: AppActions.SET_ACTIVE_WINDOW,
					//     value: data.value
					// })
					if (currentPosition !== "concoursePublished") {
						AppDispatch({
							type: AppActions.SET_ACTIVE_WINDOW,
							// value: { value: data.type, assetUrl: data.url }
							value: `${data.type}`,
						});
					}
					break;
				case HOTSPOT_TYPES.NAVIGATE:
					console.log("navigate", data);

					if (currentPosition === "concoursePublished") {
						switch (payload.data) {
							case 2:
								AppDispatch({
									type: AppActions.SET_VISTA_SRC,
									value: {
										src: concourseData?.event_settings?.main_hall_url,
										currentPosition: data.nextPostion,
									},
								});
								break;
						}
					}

					if (
						currentPosition !== "exteriorPublished" &&
						currentPosition !== "concoursePublished"
					) {
						AppDispatch({
							type: AppActions.SET_VISTA_SRC,
							value: {
								src: concourseData?.event_settings?.concourse_url,
								currentPosition: data.nextPostion,
							},
						});
					}
					// if (data.value.location) {
					//     AppDispatch({
					//         type: AppActions.SET_LOCATION,
					//         value: {
					//             data: data.value,
					//             location: locationData[data.value.location - 1]
					//         }
					//     })
					// }
					break;
				default:
					console.log("unhandled case");
					break;
			}
		} else {
			console.log("No Active HotSPot Found");
		}

		// let hotspotId = 0
		// if (stand) {
		//     hotspotId = stand.hotspots.filter(item => item.index === payload.data)[0]?.target || 0
		// } else if (hall) {
		//     hotspotId = hall.hotspots.filter(item => item.index === payload.data)[0]?.target || 0
		// } else {
		//     hotspotId = vistaLocation.hotspots[payload.data - 1]?.target || 0;
		// }

		// if (hotspotId === 0) {
		//     // eslint-disable-next-line no-console
		//     console.log("Something went wrong, hotspotId : 0");
		//     return
		// }

		// const { data } = hotSpotData.filter(hp => hp.id === hotspotId)[0]
		// console.log(data,"------current data0--------");

		// if (data) {
		//     switch (data.type) {
		//         case HOTSPOT_TYPES.ASSET:
		//             AppDispatch({
		//                 type: AppActions.SET_ACTIVE_WINDOW,
		//                 value: `Asset Viewer: ${data.value.name}`
		//             })
		//             break;
		//         case HOTSPOT_TYPES.BRAND_ZONE:
		//             break;
		//         case HOTSPOT_TYPES.VIEW:
		//             AppDispatch({
		//                 type: AppActions.SET_ACTIVE_WINDOW,
		//                 value: data.value
		//             })
		//             break;
		//         case HOTSPOT_TYPES.NAVIGATE:
		//             AppDispatch({
		//                 type: AppActions.SET_VISTA_SRC,
		//                 value: data.value.url
		//             })
		//             // if (data.value.location) {
		//             //     AppDispatch({
		//             //         type: AppActions.SET_LOCATION,
		//             //         value: {
		//             //             data: data.value,
		//             //             location: locationData[data.value.location - 1]
		//             //         }
		//             //     })
		//             // }
		//             break;
		//         default:
		//             console.log("unhandled case");
		//             break;
		//     }
		// }
	});

	// tracking being caught in vista
	const location = useCallback((payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}
	});

	const section = useCallback((payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}
	});

	const booth = useCallback((payload) => {
		console.log(payload, "-----pay load------");
		console.log(hall, "------halll ------");

		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}

		const stand = standsData.filter(
			(stand) =>
				stand?.id ===
				hall?.stands?.filter((stand) => stand?.index === payload?.data)[0]
					?.standID,
		)[0];

		if (stand) {
			stand.url = hall.stands.filter(
				(stand) => stand?.index === payload?.data,
			)[0]?.standUrl;

			if (stand.url) {
				stand.exit = Math.ceil(payload.data / 2);

				stand.lookAtBooth = payload.data;

				stand.brand_id = hall?.stands?.filter(
					(stand) => stand?.index === payload?.data,
				)[0]?.brand_id;

				stand.zones = hall?.stands?.filter(
					(stand) => stand?.index === payload?.data,
				)[0].zones;

				stand.brand_tier = hall?.stands?.filter(
					(stand) => stand?.index === payload?.data,
				)[0].brand_tier;

				if (stand?.zones) {
					stand?.zones?.forEach((item) => {
						if (item?.booth_location_id) {
							let obj = stand.hotspots.find((val) => {
								if (val?.index) {
									return val?.index === item?.booth_location_id;
								}
							});
							if (obj) {
								obj.status = item?.is_active;
							}
						}
					});
				}

				dispatchRedux(setBoothLocationNumber(stand?.brand_id));

				setBrandID(stand?.brand_id);

				AppDispatch({
					type: AppActions.SET_STAND,
					value: stand,
				});
			} else {
				toast.error("Vista Not Uploaded");
			}
		}
	});

	const exit = useCallback(async (payload) => {
		if (process.env.REACT_APP_ENV === "development") {
			// eslint-disable-next-line
		}
		console.log(payload, "booth exit");
		console.log("resetbooth data called 3");
		dispatchRedux(
			setVisitBoothData({
				tier: null,
				sequence: null,
				final_booth_url: null,
				zones: null,
			}),
		);
		dispatchRedux(
			setVistaBrandData({
				brand_id: null,
			}),
		);

		stand?.hotspots.forEach((item) => {
			item.status = false;
		});

		if (expoHallUrl) {
			hall.url = expoHallUrl;
		} else {
			let event_id = null;
			if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
				if (vistaEventId) {
					event_id = vistaEventId;
					localStorage.setItem("event_id", event_id);
				}
			} else {
				event_id = localStorage.getItem("event_id");
			}

			let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

			let response = await getConcourseDetails({
				event_id: event_id,
				current_timestamp: timeStamp,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			});

			if (response && response.data.code === 200) {
				if (response?.data?.data) {
					hall.url = response?.data?.data?.event_settings?.expo_hall_url;
				}
			}
		}

		AppDispatch({
			type: AppActions.SET_HALL,
			value: hall,
			url: `${hall.url}?media-name=${stand.exit}&trigger-overlay-name=lookAtBooth${stand.lookAtBooth}`,
		});
	});
	//  #endregion

	// Events
	useEffect(() => {
		if (manager && frame) {
			manager.on("ready", ready);
			manager.on("hotspot", hotspot);
			manager.on("location", location);
			manager.on("section", section);
			manager.on("booth", booth);
			manager.on("exit", exit);
		}

		return () => {
			if (manager && frame) {
				manager.off("ready", ready);
				manager.off("hotspot", hotspot);
				manager.off("location", location);
				manager.off("section", section);
				manager.off("booth", booth);
				manager.off("exit", exit);
			}
		};
	});

	return <Provider value={{ ...state, dispatch, frame }}>{children}</Provider>;
})(VistaContext.Provider);

export default VistaContext;
